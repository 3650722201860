const PAYMENT = 1;
const REFUND = 2;
const CASH_IN = 3;
const CASH_OUT = 4;
const REFUND_FAILURE = 5;
const INCREMENT_FREEZE = 6;
const DECREMENT_FREEZE = 7;
const LINK_CARD = 8;
const LINK_CARD_ROLLBACK_REFUND = 9;
const TYPE_TRANSFER_TO_DISBURSEMENT = 10;

const TYPE_DISPLAY = {
  [PAYMENT]: 'Constant.MerchantBalanceLogType.Payment',
  [REFUND]: 'Constant.MerchantBalanceLogType.Refund',
  [CASH_IN]: 'Constant.MerchantBalanceLogType.CashIn',
  [CASH_OUT]: 'Constant.MerchantBalanceLogType.CashOut',
  [REFUND_FAILURE]: 'Constant.MerchantBalanceLogType.RefundFailure',
  [INCREMENT_FREEZE]: 'Constant.MerchantBalanceLogType.IncrementFreeze',
  [DECREMENT_FREEZE]: 'Constant.MerchantBalanceLogType.DecrementFreeze',
  [LINK_CARD]: 'Constant.MerchantBalanceLogType.LinkCard',
  [LINK_CARD_ROLLBACK_REFUND]: 'Constant.MerchantBalanceLogType.LinkCardRollbackRefund',
  [TYPE_TRANSFER_TO_DISBURSEMENT]: 'Constant.MerchantBalanceLogType.TypeTransferToDisbursement',
};

const TYPE_DISPLAY_DISBURSEMENT = {
  [PAYMENT]: 'Constant.MerchantBalanceLogType.DisbursementPayment',
  [REFUND]: 'Constant.MerchantBalanceLogType.DisbursementRefund',
  [CASH_IN]: 'Constant.MerchantBalanceLogType.DisbursementCashIn',
  [CASH_OUT]: 'Constant.MerchantBalanceLogType.DisbursementCashOut',
  [TYPE_TRANSFER_TO_DISBURSEMENT]: 'Constant.MerchantBalanceLogType.TypeTransferToDisbursement',
};

export default {
  PAYMENT,
  REFUND,
  CASH_IN,
  CASH_OUT,
  INCREMENT_FREEZE,
  DECREMENT_FREEZE,
  TYPE_DISPLAY,
  LINK_CARD,
  LINK_CARD_ROLLBACK_REFUND,
  TYPE_DISPLAY_DISBURSEMENT,
  TYPE_TRANSFER_TO_DISBURSEMENT,
}
