// en.js

export default {
    "Sidebar": {
        "Profile": "Account",
        "Balance": "Balances",
        "BalanceFluctuationsStatistics": "Balance Management",
        "Withdraw": "Withdrawal",
        "TransactionManagement": "Transaction Management",
        "InvoiceManagement": "Invoice Management",
        "InvoiceList": "Invoice List",
        "AddInvoice": "Add Invoice",
        "TransactionList": "Transaction Search",
        "RefundManagement": "Refund",
        "ProfileInfo": "Account Information",
        "ProfileManagement": "Profile Management",
        "StatisticsOfBalanceFluctuations": "Unsettled Reconciliation Balance Changes",
        "PaymentSchoolManagement": "Tuition fee management",
        "OrderPaymentList": "Order Payment List",
        "PaymentPurpose": "Payment Purpose",
        "TransactionDisbursementManagement": "Transfer money",
        "TransactionDisbursement": "Transfer, withdrawal Transaction Search",
        "MerchantDisbursementLog": "Available Balance Changes",
        "WalletManagement": "Wallet Management",
        "MposManagement": "Mpos Management",
        "WalletBalanceFluctuate": "Enterprise Wallet Balance Fluctuate",
        "OrdersHistoryOfUser": "Orders History Of User",
        "MposInformation": "Mpos Management",
        "Language": "Language",
        "AccountDisbursementList": "Available Balance Top-up Account",
        "MerchantRequestDisbursement": "Money Transfer 247",
        "RefundInstallmentRequestManage" : "Installment Refund",
        "RechargeInformation" : "Recharge",
        "TopUpAvailableBalance" : "Unsettled Reconciliation Balance Top-up Account",
        "QRManagement" : 'StoreQR Management',
        'QRList': 'StoreQR list',
        "QRtransactions": "Transaction list of StoreQR",
        "QRnotify": "Transaction Notification",
        "QRDashboard": "Dashboard",
        "Revenue": "Revenue",
        "DisbursementByBatchList": "Batch Transfer History",
    },
    "Title": {
        "Home": "Home",
        "CreateInvoice": "Create Invoice",
        "EditInvoice": "Detail Invoice",
        "RefundList": "Refund Request List",
        "RefundDetail": "Refund Detail",
        "CreateRefund": "Create Refund Request",
        "TransactionDetail": "Transaction Detail",
        "InvoiceDetail": "Invoice Detail",
        "Profile": "Account",
        "Dashboard": "Dashboard",
        "CreateProfile": "Create Profile",
        "EditProfile": "Edit Profile",
        "ViewProfile": "Detail profile",
        "OrderPaymentDetail": "Order Payment Detail",
        "CreatePaymentPurpose": "Create Payment Purpose",
        "ViewPaymentPurpose": "Detail Payment Purpose",
        "TransactionDisbursementDetail": "Details of transfer and withdrawal transactions",
        "PaymentGatewayService": "Payment Gateway Service",
        "PayInPayOutService": "PayIn-PayOut Service",
        "AccountToTopUpAvaiableBalance": "Unsettled Reconciliation Balance Top-up Account",
        "Withdraw": "Withdrawal",
        "DisbursementByBatchList": "Batch Transfer History",
    },
    "Constant": {
        "App": {
            "BearerDisplay": {
                "Merchant": "Merchant",
                "User": "User",
            },
            "FeeInvoices": {
                "Seller": "Fee charged to merchant",
                "Buyer": "Fee charged to buyer",
            },
            "ActiveStatus": {
                "Active": "Active",
                "Inactive": "Inactive",
            },
            "FeePayer": {
                "FeePayerMerchant": "Merchant",
                "FeePayerCustomer": "Customer"
            }
        },
        "ChannelDisplay": {
            "Website": "Website",
            "Invoice": "Invoice",
            "MerchantHosted": "Merchant Hosted"
        },
        "InstallmentStatus": {
            "Canceled": "Canceled",
            "Failed": "Failed",
            "WaitSendPartner": "Wait to send",
            "SendPartner": "Sent",
            "Success": "Successful",
            "SentCancelInstallment": "Cancellation request for Installment Conversion has been submitted",
        },
        "InvoiceStatus": {
            "Unpaid": "Unpaid",
            "Processing": "Processing",
            "Success": "Paid",
        },
        "RefundStatus": {
            "Waiting": "Waiting",
            "Approved": "Approved",
            "Rejected": "Rejected",
            "Cancelled": "Cancelled",
            "Placeholder": "Status",
        },
        "RefundType": {
            "Full": "Full refund",
            "Partial": "Partial refund",
            "Placeholder": "Type of refund",
            "WarningRefundPartial": "The Transaction can be partially refunded after 24 hours of success."
        },
        "TransactionMethod": {
            "AtmCard": "ATM card",
            "CreditCard": "International Card",
            "Wallet": "E-wallet",
            "Wallet9Pay": "9Pay E-wallet",
            "WalletVNPay": "VNPay E-wallet",
            "WalletZaloPay": "ZaloPay E-wallet",
            "Collection": "Bank transfer",
            "BuyNowPayLater": "Buy Now Pay Later",
            "QRPay": "QR Pay",
            "ApplePAY": "Apple Pay",
            "Installment": "Installment",
        },
        "TransactionStatus": {
            "Cancelled": "Cancelled",
            "Failed": "Failed",
            "Processing": "Processing",
            "UnderReview": "Under Review",
            "Linked": "Linked",
            "Completed": "Successful",
            "ReceivedMoney": "Received",
            "Reversed" : "Reversed"
        },
        "TransactionType": {
            "Purchase": "Purchase",
            "Refund": "Refund",
            "Installment": "Installment",
            "LinkCard": "Link Card",
            "installmentRefund": "Installment refund",
            'recharge': "Recharge",
        },
        "ProfileStatus": {
            "Pending": "Pending",
            "Approved": "Active",
            "Rejected": "Rejected",
        },
        "MerchantBalanceLogType": {
            "Payment": "Payment",
            "Refund": "Refund",
            "CashIn": "Cash in",
            "CashOut": "Cash out",
            "RefundFailure": "Refund failure",
            "IncrementFreeze": "Freeze",
            "DecrementFreeze": "Unfreeze",
            "LinkCard": "Link card",
            "LinkCardRollbackRefund": "Refund of linked card",
            "DisbursementPayment": "Money transfer payment",
            "DisbursementRefund": " Refund transfer",
            "DisbursementCashIn": "Disbursement Cash In",
            "DisbursementCashOut": "Disbursement Cash Out",
            "TypeTransferToDisbursement":"Transfer unsettled balance to available balance",
        },
        "WalletActionType": {
            "Deposit": "Deposit",
            "Transfer": "Transfer",
            "WithDraw": "Withdraw",
            "Payment": "Payment",
            "Another": "Another",
        },
        "WalletOrderType": {
            "Billing": "Billing",
            "GameCard": "Game Card",
            "LuckyBest": "Lucky Best",
            "Mobilott": "Mobilott",
            "PhoneCard": "Phone Card",
            "MobileNetwork": "Mobile Network",
            "TopupData": "Topup Data",
            "TopupMobile": "Topup Mobile",
            "VBI": "VBI",
            "Vntrip": "Vntrip",
            "ServiceCard": "Service Card",
        },
        "WalletOrderStatus": {
            "Success": "Success",
            "Fail": "Fail",
            "Cancel": "Cancel",
            "Created": "Created",
            "ExpiredSession": "Expired Session",
            "Processing": "Processing",
            "PaymentSuccess": "Payment Success",
            "PaymentPending": "Payment Pending",
            "Another": "Another",
        },
        "TransBankInfo": {
            "CheckEnroll": "Enroll",
            "PayerAuth": "Payer Authentication",
            "Authorize": "Authorize",
            "Capture": "Capture",
            "ReverseAuth": "Reverse Authorize",
            "Success": "Success",
            "Fail": "Fail",
            "Processing": "Processing",
            "Pending": "Pending",
            "Reversal": "Reversal",
            "NotOccur": "Not Occur",
            "Bypass": "Bypass",
        },
        "RefundInstallmentReqStt": {
            "PENDING": "Pending",
            "SUCCESS": "Approved",
            "SENT": "Sent",
            "CANCEL": "Canceled",
            "FAIL": "Fail",
            "Placeholder": 'Status'
        },
        "RefundInstallmentRequested": {
            "no_fee_refund_installment_requested": "No installment conversion fee refund requested",
            "fee_refund_installment_requested": "Installment conversion fee refund requested",
        },
        "VAStatus": {
            "INACTIVE": "Inactive",
            "ACTIVE": "Active"
        },
        "DisbursementType": {
            "TRANSFER": "Transfer money",
            "WITHDRAW": "Withdraw"
        }
    },
    "Label": {
        "FeePayerTitle": "Fee Payer",
        "TransactionInfo": "Transaction Information",
        "ProfileInfo": "Profile Information",
        "CustomerInfo": "Customer's Information",
        "PaymentLinkInfo": "Payment Link Information",
        "InvoiceLink": "Invoice Link",
        "TransactionPaymentInfo": "Payment Information",
        "RefundInfo": "Request Refund Information",
        "MerchantInfo": "Customer's Information",
        "AccountInfo": "Account Information",
        "InvoiceInfo": "Invoice Information",
        "CardInfo": "Card Information",
        "InstallmentInfo": "Installment Information",
        "RefundRequest": "Refund Request",
        "AvailableBalances": "Unsettled reconciliation balance",
        "FilterByMerchant": "Filter by merchant:",
        "PaymentLink": "Payment link",
        "TheSellerLink": "Link fee charged to Merchant - Vietnamese",
        "TheBuyerLink": "Link fee charged to Buyer - Vietnamese",
        "TheSellerLinkEn": "Link fee charged to Merchant - English",
        "TheBuyerLinkEn": "Link fee charged to Buyer - English",
        "FeeTransaction": "Transaction Fee",
        "Language": "Payment Language",
        "StartDate": "Start date",
        "EndDate": "End date",
        "SignOut": "Sign Out",
        "LinkZaloAccount": "Link your Zalo account",
        "FreezeBalance": "Freeze balance",
        "MerchantBalance": "Merchant balance (VND)",
        "DisbursementBalance": "Available balances",
        "DisbursementBalanceMax": "Maximum disbursement amount",
        "TheSchoolPaymentLink": "Tuition payment link",
        "TitleQRCode": "Scan the StoreQR by your phone's camera to access to the payment link",
        "PurchaseNumber": "Payment times",
        "AddCustomer": "Add customer",
        "AddProduct": "Add product",
        "TransactionStatus": "Transaction status",
        "LearnMoreStatus": "Learn more about statuses",
        "Explain": "Explain",
        "WalletBalance": "Wallet Balance",
        "PaymentProcessingInfo": "Payment processing information",
        "Overview": "Overview",
        "Detail": "Detail",
        "CreatedBy": "Creator",
        "QRCreation": "StoreQR creation",
        "QRUpdate": "StoreQR update",
    },
    "Button": {
        "Create": "Create",
        "Back": "Back",
        "Search": "Search",
        "Cancel": "Cancel",
        "CancelRefund": "Cancel Refund",
        "Update": "Update",
        "Add": "Add",
        "Edit": "Edit",
        "Save": "Save",
        "AddAccount": "Add Account",
        "SendRequest": "Send Request",
        "Permission": "Permission",
        "Refund": "Refund",
        "Upload": "Upload",
        "Copy": "Copy",
        "OK": "OK",
        "Export": "Export",
        "ExportHistory": "Export history",
        "SaveConfig": "Save",
        "GenerateQRCode": "Generate QR code",
        "SaveImage": "Save image",
        "Close": "Close",
        "CancelInstallment": "Cancel Installment",
        "Download": "Download",
        "Confirm" : "Confirm",
        "CreateQR": "Create brand name",
        "ConfirmTransfer": "Confirm transfer money"
    },
    "Message": {
        "Required": "{name} is required",
        "Unique": "{name} already exists",
        "MinLength": "{name} must have more than {length} characters",
        "MaxLength": "{name} has a maximum of {length} characters",
        "AmountMinVND": "Price have minimum 1.000 VND",
        "AmountMinUSD": "Price has a maximum of 1 USD",
        "ConfirmPasswordNotMatch": "The re-fill password is not correct",
        "PasswordIsNotCorrect": "The password is not correct",
        "PasswordRegex": "The password must contain upper letter, lower letter, number and special character such as @$!%*#?&",
        "UsernameRegex": "Lowercase letters, numbers and underscores are allowed",
        "TransactionDoesNotExist": "The transaction is not existed, please fill in other transaction ID",
        "YouHaveOnlyCompletedASuccessfulPaymentTransaction": "You have only completed a successful payment transaction, please enter another transaction code",
        "NotSupportRefund": "Transaction does not support refund",
        "TransactionsRefundRequestWaiting": "This refund transaction is waiting to be approved, please fill in the other transaction ID",
        "TransactionsHaveBeenRefundedPastTime": "Transactions have been refunded past time {timeAllow} days",
        "TheTransactionHasBeenFullyRefunded": "The transaction has been fully refunded, please fill in other transaction ID",
        "AmountRefundMin": "The minimum refund amount is 1,000 VND",
        "TheRefundAmountCannotBeGreaterThanTheTransactionAmount": "The refund amount cannot exceed the transaction amount",
        "YouCanRefundFullWhenNotOver24h": "After 24 hours since the successful transaction, you can create partial refund.",
        "CanNotRefundRequest": "Transaction could not create a refund request",
        "UploadTypeNotAccept": "{name} has an invalid file",
        "UploadSizeMax1MB": "{name} must be smaller than 1MB",
        "UploadSizeMax1Dot5MB": "{name} must be smaller than 1.5MB",
        "Invalid": "Invalid {name}",
        "AlreadyExists": "{name} has existed",
        "BankAccountNumberRegex": "Uppercase letters, numbers are allowed",
        "AlphaNumeric": "{name} only unsigned letters and numbers are allowed",
        "AmountMin": "Minimum transaction amount is 1 VND",
        "AmountMax": "Maximum transaction amount is {amountMax} VND",
        "AmountMinByLimit": "Minimum transaction amount is {amountMinByLimit} VND",
        'RefundInstallmentRequestAlert': 'Transaction has a refund request on processing. Cannot create another request',
    },
    "Notice": {
        "Title": {
            "Confirm": "Confirm",
            "Notice": "Notification",
            "SureTodelete": "Sure to delete?",
        },
        "Message": {
            "ConfirmDeleteInvoice": "Are you sure to delete the invoice?",
            "CreateRefundSuccess": "Refund request created successfully",
            "CreateRefundFail": "Refund request created failed",
            "CreateInvoiceSuccess": "Invoice created successfully",
            "CreateInvoiceFail": "Invoice created failed",
            "UpdateInvoiceSuccess": "Invoice edited successfully",
            "UpdateInvoiceFail": "Invoice edited failed",
            "CopySuccess": "Copy successfully",
            "PermissionSuccess": "Decentralization successfully",
            "PermissionFail": "Decentralization failed",
            "CreateUserSuccess": "Account created successfully",
            "CreateUserFail": "Account created failed",
            "EditPasswordSuccess": "The password is changed successfully",
            "EditPasswordSuccessAndReload": "The password is changed successfully. The system will automatically reboot",
            "CreateProfileSuccess": "Profile created successfully",
            "CreateProfileFail": "Profile created failed",
            "EditProfileSuccess": "Profile edited successfully",
            "EditProfileFail": "Profile edited failed",
            "ConfirmDeleteProfile": "Are you sure to delete the profile?",
            "DeleteProfileSuccess": "Profile deleted successfully",
            "DeleteProfileFail": "Profile deleted failed",
            "DeleteInvoiceSuccess": "Invoice deleted successfully",
            "DeleteInvoiceFail": "Invoice deleted failed",
            "ProductInvalid": "Please enter required product information",
            "AmountLessVND": "Total payment amount cannot be lower than 10,000 VND",
            "AmountGreaterVND": "Total payment amount cannot exceed 500,000,000 VND",
            "AmountLessCurrency": "Total foreign currency payment amount cannot be lower than 15,000 VND",
            "AmountGreaterCurrency": "Total foreign currency payment amount cannot exceed 499,000,000 VND",
            "NotHaveAuthorizedCreateRefund": "You do not have permission to create refund transaction",
            "ProductLimit": "You can only add maximum 20 products in one invoice",
            "TimeExportLimit": "Please choose the time to export data, the maximum is 31 days",
            "MakingARequestToExportData": "The system is processing the data exporting request. Please do not close the browser",
            "NotEnoughBalance": "Your balance is insufficient to create order",
            "MaxLengthProductName": "Product name cannot be more than 255 characters",
            "SavePaymentPurposeSuccess": "Save Payment Purpose Success",
            "SavePaymentPurposeFail": "Save Payment Purpose Fail",
            "UpdateStatusInvoiceSuccess": "Invoice status edited successfully",
            "ConfirmActiveInvoice": "Are you sure to active the invoice?",
            "ConfirmInactiveInvoice": "Are you sure to inactive the invoice?",
            "ConfirmCancelRefund": "Are you sure to cancel the refund?",
            "CancelRefundSuccess": "Refund cancelled successfully",
            "CancelRefundFail": "Refund cancelled failed",
            "InvalidAccountInformation": "Invalid account information",
            "ValidAccountInformation": "Valid account information",
            "NotEnableGoogle2FA": "Google 2fa not enable",
            "InvalidCodeGoogle2FA": "Invalid verification code",
            "LinkLimit": "You can only add maximum 6 links",
            "UpdateLinkInfoSuccess": "Updated information success",
            "UpdateLinkInfoFail": "Updated information fail",
            "CreateRequestDisbursementFail": "Create Disbursement Request Fail",
            "CreateRequestDisbursementSuccess": "Create Disbursement Request Success",
            "InvalidBankCMS": "The chosen bank is under maintenance, please choose another bank",
            "ConfirmSendRequestCancelInstallment": "Confirm the request to cancel the installment conversion",
            'NotiCancelInstallmentSuccess': 'Request to cancel installment conversion successfully',
            'NotiCancelInstallmentFail': 'Request to cancel installment conversion failed',
            'CreateRequestRefundSuccess': 'Create a refund request for a successful installment transaction',
            'CreateQRSuccess': 'Create StoreQR success',
            'UpdateQRSuccess': 'Update StoreQR success',
            'CreateQRFail': 'Create StoreQR fail',
            'UpdateQRFail': 'Update StoreQR fail',
            'QRExist': 'StoreQR exist',
            'QRNotExist': "StoreQR doesn't exist",
            'NotEnableWithdraw': 'Partners are not yet supported to use this function. Please contact 9Pay for support.',
            'TransactionHoldMoney': "Cannot issue a refund because the transaction is suspected of fraud",
            'TransactionSuccessful': "Transaction successful",
            'TransactionFail': 'Transaction fail',
            'ExceedsTransactionLimit': 'Transaction exceeds the transfer limit',
            "NotEnoughAvailableBalance": "Your available balance  is insufficient to create request",
            "BatchIsCompleted": "Transfer has been completed, please verify"
        },
    },
    "Modal": {
        "Title": {
            "EditPassword": "Change password of account {username}",
            "CreateSimpleAccount": "Create simple account",
            "SelectPermission": "Select permission: {username}",
        },
    },
    "Language": "Language",

    /* Module detail */
    "Invoice": {
        "InvoiceCode": "Invoice number",
        "ProductNameOrService": "Product name",
        "ProductName": "Product name",
        "Currency": "Currency",
        "Amount": "Amount",
        "ProductDescription": "Product description",
        "PaymentStatus": "Payment status",
        "UserUpdate": "Account update",
        "UpdateDate": "Updated day",
        "Action": "Action",
        "StatusInvoice": "Status of Invoice",
        "Profile": "Profile",
        "Title": "Title",
        "CreateDate": 'Created date',
        "Note": "Note",
        "Policy": "Add policy",
        "AutoGen": "Continue auto generate invoice no",
        "MannualGen": "Add manual invoice no",
        "Prefix": "Prefix",
        "InvoiceCodeContinue": "Number continue",
        "ProductDetail": "Product detail",
        "Quantity": "Quantity",
        "Price": "Price",
        "Money": "Total",
        "Temporary": "Provisional calculation",
        "Discount": "Discount",
        "Adjusted": "Content",
        "AdjustedNumber": "Number",
        "Total": "Total",
        "AdjustedTooltip": "Add any amount + or - to adjust the total transaction amount, i.e. 10 or -10.",
        "Permanent": "Fixed",
        "DateCreate": "Date Create",
        "Default": "Default",
        "AmountMpos": "Total amount",
        "No": "No",
        "DetailOrder": "Add product detail",
        "TotalAmount": "Total amount",
        "One": "One time",
        "Multiple": "Multiple times",
        "AdjustedText": "Adjusted",
        "profileBlockedAlert": "This profile is currently disabled/deleted, please select another option or uncheck it.",
        "PaymentRequestInternationalCard": "Payment request via international card",
        "ProvideAddress": "Provide address",
        "ProvideEmail": "Provide email",
        "NotiExchangeRate": "Exchange rates may change according to the regulations of the card issuing bank at the time of payment.",
        "InvoiceMinimumSupported": "support a minimum amount of",
        "InvoiceMaximumSupported": "and a maximum of ",
        "Warning": "Warning",
        "Preview": "Preview",
        "HidePreview": "Hide preview",
    },
    "PreviewInvoice": {
        'TitleConfirm':'Payment confirmation',
        'TitlePage': 'Payment confirmation',
    },
    "Customer": {
        "Name": "Customer name",
        "CustomerName": "Customer name",
        "Phone": "Customer phone",
        "Address": "Customer address",
        "Email": "Email",
        // "Country": "Customer country",
    },
    "Transaction": {
        // search form
        "OrderAndRequestCode": "Order reference code/ Transaction code",
        "CardNumber": "Card number",
        "CardName": "Card name",
        "Channel": "Payment channel",
        "Status": "Status",
        "Method": "Payment method",
        "Type": "Type of transaction",
        // header table
        "No": "No",
        "TradingDate": "Updated date",
        "CreatedAt": "Creation time",
        "UpdatedAt": "Updated time",
        "OrderCode": "Transaction ID",
        "RequestCode": "Order reference code",
        "RequestAndContractCode": "Order ID / Invoice ID",
        "TypeTh": "Type of Transaction",
        "ChannelTh": "Payment channel",
        "MethodTh": "Payment method",
        "BankAndCardNumber": "Bank/ Card number",
        "Amount": "Amount",
        "ReferenceValueAndRateVND": "Reference amount / VND exchange rate",
        "FeeVND": "Fee",
        "ClientIP": "Client IP",
        "Remark":"Transfer content",
        "SenderBankName":"Sender bank name",
        "SenderBankAccountName":"Sender bank account name",
        "SenderBankAccountNo":"Sender bank account no",
        "MerchantName": "Merchant name",
        "UpdatedBy": "invoice creator",
        // detail
        "PaymentValue": "Transaction amount",
        "Currency": "Foreign currency transaction value",
        "ExcRate": "Exchange rate",
        "ChargePaymentFee": "Payment fee",
        "RefundPaymentFee": "Refund payment fee",
        "InstallmentPaymentFee": "Installment payment fee",
        "ChargeProcessingFee": "Processing fee",
        "AmountActually": "Amount Received",
        "ActualAmountOfRefund": "Actual refund amount",
        "PaymentMethod": "Payment method",
        "RefundType": "Refund type",
        "FailureReason": "Failure reason",
        "InstallmentFee": "Instalment Fee",
        "ActualAmountMinus": "Actual amount minus",
        "ChooseColumn": "Choose column",
        "SelectAll": "Select all",
        "SelectCreator": "Invoice creator",
        "TotalTransaction": "Total transaction quantity",
        "TotalFeeTransaction": "Total transaction fees",
        "TotalAmountTransaction": "Total value of successful transactions",
        "TotalAmountReceived": "Total actual received amount",
    },
    "Refund": {
        "RequestAndPaymentCode": "Order reference code/ Transaction code",
        "Time": "Time",
        "PaymentNo": "Transaction reference code",
        "PaymentOriginCode": "Original transaction ID",
        "PaymentOriginValue": "Original transaction amount",
        "RefundType": "Type of refund",
        "AmountRefund": "Amount refund",
        "Status": "Status",
        "RequestCode": "Order reference code",
        "ReceivePaymentFee": "Refunded payment fee",
        "ReceiveProcessingFee": "Refunded transaction processing fee",
        "Description": "Reason",
        "RequestDate": "Request date",
        "AmountRefunded": "Amount has been refunded",
        "AmountWantRefund": "Amount want to refund",
        "No": "No",
        "Action": "Action",
        "MaximumRefundAmount": 'Maximum refund amount',
        "MaximumRefundAmountTooltip": "Max refund amount = Transaction value - Refunded amount - Customer's transaction fee",
        "InstallmentConversionFee": "Installment Conversion Fee",
        "InstallmentConversionRefundRequest": "Installment Conversion Refund Request",
        "InstallmentConversionRefundRequestTooltip": "The merchant may request a refund of the installment fee, however, 9Pay will verify with the bank to confirm the refundability of the fee",
        "AmountRefundedTooltip": "Refund amount includes the amount refunded and the amount requested for refund",
        "Include": "Include",
    },
    "BankInfo": {
        "CardName": "Card holder",
        "CardNumber": "Card number",
        "BankCode": "Card brand",
        "BankName": "Bank name",
        "ChooseBank": "Choose bank",
        "BankAccountNumber": "Bank account number",
        "AccountHolderName": "Account holder name",
    },
    "Profile": {
        "Tab": {
            "Info": "Information",
            "MemberAccount": "Member Account",
            "PaymentLinkInfo": "Payment link information",
        },
        "No": "No",
        "CreateDate": "Creation day",
        "ProviderName": "Profile name",
        "IDProfile": "ID profile",
        "ProviderLogo": "Logo",
        "Address": "Address",
        "ProviderWebsite": "Provider website",
        "Phone": "Phone",
        "Email": "Email",
        "TermsAndPolicy": "Terms and Conditions",
        "ReasonForRefusal": "Reason for refusal",
        "Status": "Status",
        "Action": "Action",
        "PaymentLinkInfoText": {
            "StallName": "Stall Name",
            "StoreInfo": "Store Information",
            "Description": "Description",
            "DescriptionVn": "Enter Vietnamese description...",
            "DescriptionEn": "Enter English description...",
            "LinkManagement": "Link Management",
            "LinkTotal": "Total Link",
            "AddLink": "Add Link",
            "Link": "Link",
            "SelectLink": "Select Link",
        },
        "IsActive": "On/Off profile"
    },
    "Merchant": {
        "Name": "Merchant name",
        "RepresentativeName": "Legal representative name",
        "BusinessRegistrationNumber": "Business registration number",
        "Phone": "Phone",
        "Address": "Address",
        "TaxCode": "Tax Code",
        "Email": "Email",
        "IPNUrl": "IPN Url",
        "ReturnUrl": "Return Url",
    },
    "Account": {
        "Name": "Name",
        "Password": "Password",
        "No": "No",
        "Username": "Username",
        "Action": "Action",
        "CurrentPassword": "Current password",
        "PasswordConfirm": "Re-enter password",
        "MerchantPassword": "Merchant password",
        "SecurityTwoFactor": "2-Factor Authenticator (2FA)",
        "SignTwoFactor": "Register",
        "StatusOn": "On",
        "StatusOff": "Off",
        "CancelTwoFactor": "Cancel register"
    },
    "Installment": {
        "CardName": "Card holder",
        "BankInstallment": "Issuer bank",
        "Period": "Period",
        "PeriodShow": "{period} months",
        "FeeBearer": "Fee payer",
        "ReceivedFee": "Installment fee",
        "RefundReceivedFee": "Refundable installment fee",
        "ClientPhone": "Customer phone number",
        "Status": "Installment conversion status",
        "Note": "Note",
    },
    "Dashboard": {
        "Revenue": "Revenue",
        "Refund": "Refund",
        "Today": "Today",
        "ThisWeek": "This Week",
        "ThisMonth": "This Month",
        "ThisQuarter": "This Quarter",
        "ByDay": "By Day",
        "ByWeek": "By Week",
        "ByMonth": "By Month",
        "TotalDeposit": "Total Deposit",
        "TotalWithdrawals": "Total Approved Withdrawal",
        "AllMerchant": "All merchant",
        "Transactions": "transactions",
        "TransactionStatistics": "Transaction statistics:",
        "DepositAndWithdrawalStatistics": "Recharge/withdrawal statistics:",
        "ExchangeRate": "Exchange rate:",
        "Week": "Week",
        "Month": "Month",
        "GMV": "GMV (VNĐ)",
        "NumberOfTransactions": "Transactions",
        "Currency": "Currency",
        "CurrencyCode": "Currency code",
        "VND": "VND",
        "RevenueReport": "Revenue report",
        "TopRevenue": "Top revenue",
        "TotalRevenue": "Total revenue",
        "RevenueRate": "Revenue rate",
        "Yesterday": "Yesterday",
        "LastMonth": "Last month",
        "ThisYear": "This year",
        "Others": "Others",
        "AvailableBalance": "Available balance",
        "AlertFilterDateQR": "Please select a maximum of 31 days",
        "TodaysRevenue": "Today's revenue",
        "Withdrawals": "Withdrawal",
        "RecentTransactions": "Recent transactions",
        "ViewAll": "View all",
        "SelectDate": "Select date",
        "QRRevenue": "Revenue",
    },
    "MerchantBalanceLog": {
        "AllType": "All changes",
        // Total info
        "TotalPayment": "Total payment amount",
        "TotalRefund": "Total refund amount",
        "TotalIncrease": "Increase unsettled reconciliation balance",
        "TotalReduced": "Reduced unsettled reconciliation balance",
        "TotalCashOut": "Total recharge amount",
        "TotalCashIn": "Total approved withdrawal amount",
        "OpeningAvailableBalance": "Opening unsettled reconciliation balance",
        "ClosingAvailableBalance": "Closing unsettled reconciliation balance",
        "FreezeBalance": "Freeze balance",
        "OpenFreezeBalance": "Opened Freeze Amount",
        // Table
        "No": "No",
        "MerchantName": "Merchant name",
        "CreateDate": "Create date",
        "Type": "Type of changes",
        "BeforeBalance": "Balance before updates",
        "Amount": "Updated amount",
        "AfterBalance": "Balance after updates",
        "Description": "Description",
    },
    "MerchantDisbursementLog": {
        "AllType": "All changes",
        // Total info
        "TotalPayment": "Total money transfer payment transactions",
        "TotalRefund": "Total money transfer payment transactions",
        "TotalCashOut": "Total withdrawal amount",
        "TotalCashIn": "Total recharge amount",
        "OpeningDisbursementBalance": "Opening available balance",
        "ClosingDisbursementBalance": "Closing available balance",
        // Table
        "No": "No",
        "MerchantName": "Merchant name",
        "CreateDate": "Create date",
        "Type": "Type of changes",
        "BeforeBalance": "Balance before updates",
        "Amount": "Updated amount",
        "AfterBalance": "Balance after updates",
    },
    "ShowPaginateFooter": "Show {total_row} of the total {total} transactions",
    "ShowPaginateFooterInvoice": "Show {total_row} of the total {total} invoices",
    "ShowPaginateFooterProfiles": "Show {total_row} of the total {total} profiles",
    "ShowPaginateFooterRecords": "Show {total_row} of the total {total} records",
    "Mpos": {
        "title": "Billing information",
        "amount": "Amount*",
        "telephone": "Phone number",
        "description" : "Description (Maximum 255 characters)",
        "textCondition": "I have read and agreed with ",
        "linkTextCondition": "terms and conditions.",
        "pay": "Payment",
        "payInstallment": "Pay Installment",
        'PayerInformation': "Payer Information",
        'Email': "Email",
        'Address': "Address",
        'TooltipVerify': "Merchant is verified by 9Pay",
        "validate": {
            "amount": "Payment amount is required.",
            "telephone": "Phone is required.",
            "min": "Minimum payout amount is {min} {currency}",
            "max": "Maximum payout amount is {max} {currency}",
            "phoneInvalid": "Phone number invalid"
        }
    },
    "PermissionGroup": {
        "manageProfile": "Account management",
        "manageTransaction": "Transaction management",
        "manageRefund": "Refund",
        "manageSchoolPayment": "Tuition fee management",
        "manageTransactionDisbursement": "Transfer money",
        "manageWallet": "Enterprise wallet management",
        "manageMpos": "Mpos management",
        "manageRechargeAccount": "Recharge",
        "manageQR": "StoreQR manage",
        "manageBalance": "withdraw",
        "balanceFluctuationsStatistics": "view balance fluctuations statistics"
    },
    "Permission": {
        "canViewProfile": "View member information",
        "canViewListProfile": "View list profiles",
        "canViewDetailProfile": "View profile details",
        "canAddProfile": "Add profile",
        "canEditProfile": "Edit profile",
        "canDeleteProfile": "Delete profile",
        "canViewListInvoice": "View list invoices",
        "canViewDetailInvoice": "View invoice details",
        "canAddInvoice": "Add invoice",
        "canEditInvoice": "Edit invoice",
        "canDeleteInvoice": "Delete unpaid invoice",
        "canViewListTransaction": "View list transactions",
        "canViewDetailTransaction": "View transaction details",
        "canViewBalanceFluctuationsStatistics": "Check unsettled Reconciliation Balance Changes",
        "canViewListRefund": "View list refund requests",
        "canViewDetailRefund": "View refund details",
        "canAddRefund": "Add and cancel refund requests",
        "canViewOrderPaymentPageList": "View Payment list",
        "canViewOrderPaymentPageDetail": "Vew Payment detail",
        "canViewListPaymentPurpose": "View Payment Purpose list",
        "canViewDetailPaymentPurpose": "View Payment Purpose detail",
        "canAddPaymentPurpose": "Add Payment Purpose",
        "canEditPaymentPurpose": "Edit Payment Purpose",
        "canViewTransactionDisbursementList": "View list transfer and withdrawal transactions",
        "canViewTransactionDisbursementDetail": "View details of transfer and withdrawal transactions",
        "canViewMerchantDisbursementLog": "Check available Balance Changes",
        "canViewWalletBalanceFluctuate": "View enterprise wallet balance fluctuate",
        "canViewUserOrderHistory": "View orders history of user",
        "canViewListRefundInstallmentRequest": "Can view list refund installment request",
        "canViewPaymentLinkInfo": "View Mpos Management",
        "canUpdatePaymentLinkInfo": "Edit Mpos Management",
        "canAddMerchantRequestDisbursement": "Money Transfer 247",
        "canViewAccountDisbursementList": "Check available balance for account",
        "canViewOnlyInvoicesGeneratedByAccount": "View only invoices generated by the account",
        "canViewTopUpAvailableBalance": "Check unsettled reconciliation balance for account",
        "canViewQRList": "View StoreQR list",
        "canAddQR": "Add StoreQR",
        "canEditQR": "Edit StoreQR",
        "canViewListTransactionsQR": "View list transactions StoreQR",
        "canViewDetailTransactionsQR": "View detail transaction StoreQR",
        "canRefundTransactionsQR": "Create refund transaction StoreQR",
        "canViewNotificationQR": "View transaction notification recipient list",
        "canAddMember":"Add/edit/delete member",
        "canWithdrawBalance": "Withdrawal"
    },
    "Tooltip": {
        "Delete": "Delete",
        "Duplicate": "Duplicate",
        "View": "Detail",
        "ViewTransaction": "Search",
        "Edit": "Edit",
        "InfoProfile": "The supplier's information is automatically the same as the agent's information if you do not select profile",
        "Active": "Active",
        "Inactive": "Inactive",
        "Cancel": "Cancel",
        "Installment": "When choosing installment payment, it is mandatory to choose International card method",
        "QRCode": "QR code",
        "Refund": "Refund",
        "CheckInfo": "Check information",
        "TransferMoney": "Transfer money",
        "CheckAccount": "Just verify if the account has valid data"
    },
    "PageNotFound": {
        "Content": "You do not have permission to use this function. Please contact the supplier of this order to get the support for the order. Thank you."
    },
    "PaymentSchool": {
        "PaymentContent": "Payment note",
        "AddRulePayment": "Add Rule Payment",
        "TransactionFee": "Transaction fee",
        "AddLine": "Add Line",
        "ConfirmAction": "Are you sure you want to do this?",
        "StudentInfo": "Student info",
        "PaymentPurpose": "Payment purpose",
        "StudentName": "Student name",
        "StudentCode": "Student code",
        "Address": "Address",
        "Grade": "Grade",
        "TransactionInfo": "Transaction info",
        "OrderCode": "Order code",
        "PayerInfo": "Payer info",
        "PayerName": "Payer name",
        "PayerPhone": "Payer phone",
        "Relationship": "Relationship",
        "PayerEmail": "Email",
        "PaymentNote": "Payment note",
        "PaymentInfo": "Payment info",
        "Amount": "Amount",
        "Action": "Action",
        "SearchName": "Student name/ code",
    },
    "Disbursement": {
        "Title": "Money transfer 247",
        "DisbursementByBatch": "Money transfer by batch",
        "AccountNo": "Account number",
        "AccountName": "Account name",
        "Bank": "Bank",
        "Object": "Object",
        "Content": "Content",
        "Balance": "Available balances",
        "Request": {
            "Bank": "Bank",
            "TransferTo": "Transfer to",
            "AccountNumber": "Account Number",
            "CardNumber": "Card Number",
            "AccountNumberCardNumber": "Account Number/Card Number",
            "AccountName": "Account Name",
            "Amount": "Transaction Amount",
            "Content": "Transfer Description",
            "Code": "Authentication Code",
            "Transfer": "Transfer",
            "Check": "Check",
            "TextAuthentication": "Please enable two-fator authentication to receive verification code",
            "ReceivingBank": "Receiving Bank",
            "TransferAmount": "Transfer Amount",
            "All": "All",
            "InvalidAccount": "Recipient Account/Card Number does not exist",
            "Fee": "Fee",
            "TotalAmount": "Total amount",
            "IncorrectAuthenticationCode": "Incorrect authentication code",
            "AuthenticationCodeRequired": "Authentication Code is required",
        },
        "FileUpload": "File Upload",
        "ChooseFile": "Select payment file",
        "ChooseFileText": "Drop your .xls file here or",
        "ChooseFileTextLink": "click here to Upload",
        "ChooseFileTextLimit": "(Maximum is 1MB)",
        "Download": "Download sample file",
        "Note": "Note",
        "NoteMinMoney": "Minimum transfer amount is 1 VND/recipient",
        "NoteBank": "Some banks will receive money the next working day if they do not support 24/7 Quick Payment",
        "ButtonCheck": "Check information",
        "Mimes": "Invalid file upload, please choose another file",
        "Sizes": "File exceeds allowed size limit, please choose another file",
        "Lines": "Line",
        "Description": "Description",
        "TotalAccount": "Total number of transaction: ",
        "ValidAccount": "Valid transaction",
        "InvalidAccount": "Invalid transaction",
        "InvalidAmount": "Invalid amount",
        "ValidAmount": "Valid amount",
        "TotalAmount": "Total amount: ",
        "TransactionAmount": "Transfer amount",
        "Fee": "Fee",
        "BankCode": "Bank code",
        "BankAccount": "Bank account number",
        "BankAccountName": "Account name",
        "Amount": "Amount (VND)",
        "DescriptionTrans": "Transfer content",
        "Status": "Status",
        "Reason": "Reason",
        "Action": "Action",
        "AccountStatus": {
            "Invalid": "Invalid data",
            "Valid": "Valid data",
            "Processing": "Processing",
            "InvalidAccount": "Invalid account",
            "ValidAccount": "Valid account"
        },
        "Validate": {
            "AccountName": "Account name is incorrect",
            "Title": "File upload failed, check results:",
            "FileUpload": "File upload failed, please select another file"
        },
        "TransferMoney": "Transfer money",
        "Return": "Return",
        "HistoryStatus": {
            "Failed": "Failed",
            "Initialize": "Initialize",
            "Processing": "Processing",
            "Completed": "Completed"
        },
        "RequestTime": "Request time",
        "TotalAccountList": "Total number of transactions",
        "TotalAmountList": "Total amount",
        "TransactionStatus": "Transaction status",
        "ConfirmSuccess": "You have successfully sent your request",
        "Continue": "Continue",
        "Check": "Check",
        "CheckAccount": "Check account",
        "CreatedBy": "Created by",
        "Yes": "Yes",
        "No": "No",
        "ConfirmText": "Are you sure?",
        "IsChoose": "Transfer account selected",
        "IsNotChoose": "Transfer account not selected",
        "ChoosePlaceholder": "Account",
        "ActionLog": "History of execution",
        "ActionDate": "Time of execution",
        "ActionName": "Action",
        "Create": "Create",
        "Update": "Update",
        "Confirm": "Confirm transfer money",
        "IsOffFeeByBatch": "You haven't signed up for the service required to execute the transfer"
    },
    "Explain": {
        "Completed": "Customer makes transaction successfully",
        "ReceivedMoney": "Customer makes bank transfer but the amount does not match with the order amount",
        "Cancelled": "Customer chooses to cancel before payment",
        "Failed": "Customer makes payment failed",
        "Processing": "The system is processing the transaction",
        "UnderReview": "The system is waiting for the response from the bank",
        "Linked": "Customer connects card successfully",
    },
    "BalanceLogWallet": {
        "AllType": "All changes",
        "TotalTransaction": "Total Payment",
        "TotalAmount": "Total Amount",
        // Table
        "No": "No",
        "Code": "Code",
        "ActionType": "Action Type",
        "Amount": "Amount",
        "Description": "Description",
        "PaymentAt": "Payment At",
    },
    "OrdersHistoryWallet": {
        "OrderCode": "Order Code",
        "AllStatus": "Status",
        "Phone": "Phone",
        "AllType": "All changes",
        "TotalTransaction": "Total Payment",
        "TotalAmount": "Total Amount",
        // Table
        "No": "No",
        "Code": "Code",
        "ActionType": "Type",
        "Amount": "Amount",
        "Status": "Status",
        "Description": "Description",
        "PaymentAt": "Created At",
        "MerchantName": "Merchant Name",
    },
    "TransBankInfo": {
        "Step": "Step",
        "Status": "Status",
        "Message": "Message"
    },
    "AccountDisbursementList":{
        "Title": "Details of Available balance top-up account",
        "Description": "Transfer money to your account/card number to top up your balance",
        "No": "NO",
        "Type": "Account type",
        "BankAccountType": "Bank Account Number/Card number",
        "AccountHolderName": "Account Holder Name",
        "Bank": "Bank",
        "Status": "Status",
        'Active': 'Active',
        'Inactive': 'Inactive',
        'BankAccountNumber': 'Bank Account Number',
        'BankCardNumber': 'Bank Card Number'
    },
    "VirtualAccount": {
        "No": "NO",
        "Type": "Account type",
        "BankAccountType": "Bank Account Number/Card number",
        "AccountHolderName": "Account Holder Name",
        "Bank": "Bank",
        "Status": "Status",
        'Active': 'Active',
        'Inactive': 'Inactive',
        "Client": 'Client',
        "BranchName": "Brand name",
        "Merchant": "Merchant",
        "Content": "Transfer money to your account/card number to top up your balance",
        "Heading": "Unsettled reconciliation balance deposit account information",
        "IdentifierCode": "Identifier code",
        "BankAccountNo": "Bank account no",
        "BankAccountName": "Bank account name",
        "Action": "Action",
        "CreatedAt": "Created at",
        "UpdatedAt": "Updated at",
        "ClientId": "Brand name",
        "RequestAmount": "Payment amount",
        "MerchantName": "Merchant name",
        "CorrectAmountReqForCollection": "Request for the account to collect the correct amount",
        "AcceptAnyAmount": "Accept any amount",
        "CollectTheCorrectAmount": "Collect the correct amount",
        "Placeholder": {
            "ChoosingBank": "Choose a bank"
        },
        "Validate": {
            "BankIsRequired": "Bank is required",
            "ClientIdIsRequired": "Client id is required",
            "ClientIdContainsSpecialCharacters": "Client id contains special characters",
            "ClientIdContainsVietNameseCharacters": "Client id contains VietNamese characters",
            "BankAccountNameIsRequired": "Bank account name is required",
            "BankAccountNameContainsSpecialCharacters": "Bank account name contains special characters",
            "BankAccountNameContainsVietNameseCharacters": "Bank account name contains VietNamese characters",
            "RequestAmountIsRequired": "Request amount is required",
            "BankAccountNameMaxLength": "Bank account name can't be longer than 32 characters",
            "BranchNameMaxLength": "Branch name can't be longer than 200 characters",
            "BranchNameIsRequired": "Branch name is required",
            "BranchNameContainsSpecialCharacters": "Branch name contains special characters",
            "BranchNameContainsVietNameseCharacters": "Branch name contains VietNamese characters",
        },
        "ScanToPay": "SCAN TO PAY",
        "EveryValue": "All amount",
        "All": "All",
        "ModalRequestAmount": "Request amount",
        "BranchNameLabel": "Name helps distinguish, report revenue among QR codes",
        "BankAccountNameLabel": "Display name when customers scan the QR code",
        "ViewQRCode": "View QR Code",
        "ViewHistoryTrans": "Transaction History",
        "QRCode" : "QR code",
    },
    "QR":{
        "NotifySub1": "Brand and members receive transaction notification for successful payments via Zalo messages.",
        "NotifySub2": "To receive notifications, please refer to the",
        "NotifySub3": "Zalo OA linking guide",
        "No": "No",
        "Fullname": "Member Name",
        "Phone": "Phone Number",
        "VirtualAccount": "Brand name",
        "SelectVirtualAccount": "Choose brand",
        "ZaloOA": "Zalo linking structure",
        "Action": "Action",
        "AddUserLabel":"Add new member",
        "ViewUserLabel":"View member",
        "EditUserLabel":"Edit member",
        "DeleteUserLabel":"Delete member",
        "CreateUserSuccess": "Add member success",
        "CreateUserFail": "Add member fail",
        "CreateUserFailPhone": "Phone Number already exist",
        "UpdateUserSuccess": "Update member success",
        "UpdateUserFail": "Update member fail",
        "ConfirmDeleteUser":"Are you sure you want to delete this member?",
        "DeleteUserFail": "Delete member fail",
        "DeleteUserSuccess":"Delete member success",
        "TitleSearch":"Brand name",
        "AccountName": "Account name",
        "AccountNumber": "Account number",
        "TransactionAmount": "Transaction amount",
        "BrandName": "Brand name",
        "OrderCode": "Order code",
        "CreationTime": "Creation time",
        "TotalAmount": "Total transaction amount",
        "TotalCount": "Total transactions",
        "DocumentZalo": "Zalo OA linking guide",
        "DocumentZaloContent":{
            "step1" :{
                "text1" : "Step 1: ",
                "text2" : "Access the Zalo application, search for ",
                "text3" :'“Cổng thanh toán 9Pay”',
                "text4" : ". Select the ",
                "text5" : '“Cổng thanh toán 9Pay”',
                "text6" : " => choose ",
                "text7" : "Follow",
                "img" : "zalo_img_step1_vi",
            },
            "step2" :{
                "text1" : "Step 2: ",
                "text2" : "Send a message to ",
                "text3" :'“Cổng thanh toán 9Pay” ',
                "text4" : "according to the ",
                "text5" : "Zalo linking structure ",
                "text6" : "in the member List to connect and receive results (for example: #0987654321).",
                "img" : "zalo_img_step2_vi",
            },
            "step3" :{
                "text1" : "Step 3: ",
                "text2" : "Receive notifications of successful payment transactions upon their initiation."
            },
        },
        "NoTransactions": "No transactions",
    },
    "Balance": {
        "Title" : {
            "accountNo": "Recipient’s account",
            "accountName": "Account name",
            "bank": "Bank",
            "amount": "Amount",
            "description": "Description",
            "otp": "Authentication Code",
            "descriptionOtp": "Please enable two-fator authentication to receive verification code",
            "availableBalance": "Available Balance",
            
        },
        "Placeholder": {
            "chosseAccountNo": "Select recipient’s account",
            "amountMin": "Min 1,000",
            "description": "Enter description",
            "otp": "Google authenticator code",

        },
        "Alert": {
            "success": "Success",
            "successDescriptionOne": "Your withdrawal request has been received.",
            "sucessDescriptionTwo": "We will process it in a few minutes.",
            "successDescriptionThree": "Thank you."
        },
        "ButtonTitle": {
            "withdraw": "Withdrawal",
            "close": "Close",
            "viewTransaction": "View transaction",
            "all": "All",
            "Forward": "Forward",
        }
        
    }
};
