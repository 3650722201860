<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
    </PageTitle>

    <section class="section">
      <a-card class="mb-30 shadow">
        <div>
          <h3>{{ $t('AccountDisbursementList.Title') }}</h3>
          <p>{{ $t('AccountDisbursementList.Description') }}</p>
          <a-table
              :data-source="getAccountList.accounts"
              :columns="columns"
              :row-key="record => record.id"
              :pagination="false"
              class="mb-30 nowrap"
              :scroll="{x: 'auto'}">

            <template #customTitleNo>
              {{ $t('AccountDisbursementList.No') }}
            </template>
            <template slot="no" slot-scope="text, record, index">
              <div>{{ (currentPage - 1) * getAccountList.perPage + index + 1 }}</div>
            </template>

            <template #customTitleAccountType>
              {{ $t('AccountDisbursementList.Type') }}
            </template>
            <template slot="account_type" slot-scope="text, record">
              {{ $t('AccountDisbursementList.'+accountType[record.account_type]) }}
            </template>

            <template #customTitleAccountNumber>
              {{ $t('AccountDisbursementList.BankAccountType') }}
            </template>
            <template slot="account_number" slot-scope="text, record">
              <div>{{ record.account_number }}</div>
            </template>

            <template #customTitleAccountName>
              {{ $t('AccountDisbursementList.AccountHolderName') }}
            </template>
            <template slot="account_name" slot-scope="text, record">
              <div>{{ record.account_name }}</div>
            </template>

            <template #customTitleBankCode>
              {{ $t('AccountDisbursementList.Bank') }}
            </template>
            <template slot="bank_code" slot-scope="text, record">
              <div>{{ record.bank_code }}</div>
            </template>

            <template #customTitleStatus>
              {{ $t('AccountDisbursementList.Status') }}
            </template>
            <template slot="status" slot-scope="text, record">
              <div>{{ $t('AccountDisbursementList.'+status[record.status]) }}</div>
            </template>
          </a-table>

          <a-row>
            <a-col :span="8">
              {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(getAccountList.total, getAccountList.perPage, getAccountList.currentPage), 'total': getAccountList.total}) }}
            </a-col>
            <a-col :span="16" align="end">
              <a-pagination
                  v-model="currentPage"
                  :current="currentPage"
                  :page-size="getAccountList.perPage"
                  :total="getAccountList.total"
              />
            </a-col>
          </a-row>
        </div>
      </a-card>
    </section>
  </AppMasterVue>
</template>

<script>

import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import {mapGetters} from "vuex";
import helper from "@/until/helper";

export default {
  name: 'AccountDisbursementList',
  components: {
    AppMasterVue,
    PageTitle
  },
  data(){
    return {
      title: 'Sidebar.AccountDisbursementList',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.TransactionDisbursementManagement',
          routeName: null,
          active: false
        },
        {
          name: 'Sidebar.AccountDisbursementList',
          routeName: 'AccountDisbursementList',
          active: true,
        },
      ],
      columns: [
        {
          slots: { title: "customTitleNo" },
          key: "no",
          scopedSlots: { customRender: "no" },
          align: 'center'
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchant" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "account_type",
          slots: { title: "customTitleAccountType" },
          scopedSlots: { customRender: "account_type" },
        },
        {
          key: "account_number",
          slots: { title: "customTitleAccountNumber" },
          scopedSlots: { customRender: "account_number" },
          align: 'left'
        },
        {
          key: "account_name",
          slots: { title: "customTitleAccountName" },
          scopedSlots: { customRender: "account_name" },
          align: 'left'
        },
        {
          key: "bank_code",
          slots: { title: "customTitleBankCode" },
          scopedSlots: { customRender: "bank_code" },
          align: 'left'
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
          align: 'center'
        }
      ],
      accountType: {
        0: 'BankAccountNumber',
        1: 'BankCardNumber'
      },
      status:{
        0: 'Inactive',
        1: 'Active'
      },
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      searchInput: {},
      merchantFilter: this.isset(this.$route.query.merchant_id) ? this.$route.query.merchant_id : [],
    }
  },
  computed:{
    ...mapGetters({
      getAccountList: 'account/getAccountList',
      merchants: 'authentications/getMerchants',
    })
  },
  mounted(){
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
      this.searchInput.merchant_id = this.merchantFilter;
    } else {
      this.searchInput.merchant_id = parseInt(this.merchants);
    }
    this.callApiGetListAccount();
  },
  methods:{
    isset: helper.isset,
    callApiGetListAccount() {
      this.$store.dispatch("account/getListAccount", this.searchInput);
    },
    setRouter() {
      this.$router.replace({
        name: "AccountDisbursementList",
        query: this.searchInput,
      }).catch(() => {});
    },
    getCurrentTotalRow: helper.getCurrentTotalRow,
  },
  watch:{
    currentPage: {
      handler: function (value) {
        this.searchInput.page = value;
        this.setRouter();
        this.callApiGetListAccount();
      }
    }
  }
}

</script>
