import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user/index'
import authentications from './modules/authentication/index'
import alerts from './modules/alerts'
import transaction from './modules/transaction'
import list from './modules/list'
import invoice from './modules/invoice'
import refund from './modules/refund'
import dashboard from './modules/dashboard'
import profile from './modules/profile'
import merchantBalanceLog from './modules/merchantBalanceLog'
import merchantDisbursementLog from './modules/merchantDisbursementLog'
import paymentSchool from './modules/paymentSchool'
import wallet from './modules/wallet'
import account from './modules/account'
import refundInstallmentRequest from './modules/refundInstallmentRequest'
import virtualUser from './modules/virtualUser'
import virtualAccount from './modules/rechargeInformation'
import qr from './modules/virtualAccount'


Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
        user,
        authentications,
        alerts,
        transaction,
        list,
        invoice,
        refund,
        dashboard,
        profile,
        merchantBalanceLog,
        merchantDisbursementLog,
        paymentSchool,
        wallet,
        account,
        refundInstallmentRequest,
        virtualUser,
        virtualAccount,
        qr
      } 
  })
export default store
